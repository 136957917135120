<template>
    <div>

    </div>
</template>

<script>

export default {
    props: [
        'socket',
        'user',
        'timestampDiff',
        'mediaURL',
        'search'
    ],
    data() {
        return {
            chats: [],
            loading: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            
        },
        async getChats() {

        }
    }
}
</script>